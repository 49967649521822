import { RoutesProvider } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Router, useLocation } from 'wouter';

// import * as AppPropTypes from '../lib/PropTypes';
import Routes from './Routes';

const propTypes = {
    intl: PropTypes.shape({
        locale: PropTypes.string,
        messages: PropTypes.oneOfType([
            PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
            PropTypes.objectOf(PropTypes.string),
        ]),
    }),
    routes: PropTypes.objectOf(PropTypes.string),
};

const defaultProps = {
    intl: null,
    routes: {
        home: '/',
        homeWithLocale: '/:locale',
        homeWithLocaleSimple: '/:locale/simple',
        screenWithLocale: '/:locale/:screen',
        screenWithLocaleSimple: '/:locale/simple/:screen',
    },
};

function App({ intl, routes }) {
    const [location] = useLocation();
    const { messages = {} } = intl || {};

    // Old url patterns
    useEffect(() => {
        const numberMatch = location !== null ? location.match(/^\/[0-9]+$/) : null;
        if (numberMatch !== null) {
            const [firstMatch = ''] = numberMatch || [];
            if (firstMatch !== null) {
                window.location.href = `/fr${firstMatch}`;
            }
        }
    }, []);

    const isEnglish = useMemo(
        () => (location !== null ? location.match(/^\/en/) !== null : false),
        [location],
    );

    const finalLocale = useMemo(() => (isEnglish ? 'en' : 'fr'), [isEnglish]);

    return (
        <IntlProvider locale={finalLocale} messages={messages[finalLocale] || messages}>
            <Router>
                <RoutesProvider routes={routes}>
                    <Routes />
                </RoutesProvider>
            </Router>
        </IntlProvider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
