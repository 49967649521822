import { useRoutes } from '@folklore/routes';
import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'wouter';

// import { useUrlGenerator } from '@folklore/routes';
// import * as AppPropTypes from '../lib/PropTypes';
import MainLayout from './layouts/Main';
import HomePage from './pages/Home';

import '../styles/styles.scss';

const propTypes = {};

const defaultProps = {};

function Routes() {
    const routes = useRoutes() || {};
    return (
        <Switch>
            <Redirect path={routes.home} to="/fr" />
            <Route path={routes.homeWithLocale}>
                <MainLayout>
                    <HomePage />
                </MainLayout>
            </Route>
            <Route path={routes.homeWithLocaleSimple}>
                <MainLayout>
                    <HomePage simple />
                </MainLayout>
            </Route>
            <Route path={routes.screenWithLocaleSimple}>
                <MainLayout>
                    <HomePage simple />
                </MainLayout>
            </Route>
            <Route path={routes.homeWithLocale}>
                <MainLayout>
                    <HomePage />
                </MainLayout>
            </Route>
            <Route path={routes.screenWithLocale}>
                <MainLayout>
                    <HomePage />
                </MainLayout>
            </Route>
            <Route>
                <MainLayout>
                    <HomePage />
                </MainLayout>
            </Route>
        </Switch>
    );
}

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
