import { useUrlGenerator } from '@folklore/routes';
import '@micromag/intl/locale/fr';
import Micromag from '@micromag/viewer';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'wouter';

import styles from '../../styles/pages/home.module.scss';

// import * as AppPropTypes from '../../lib/PropTypes';
import english from '../../micromag/en.json';
import french from '../../micromag/fr.json';

const propTypes = {
    simple: PropTypes.bool,
};

const defaultProps = {
    simple: false,
};

function HomePage({ simple }) {
    const { locale } = useIntl();
    const params = useParams();
    const { screen = null } = params || {};

    const url = useUrlGenerator();

    const trackPdf = useCallback(() => {
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push({
                event: 'eventInteraction',
                eventCategory: 'pdf',
                eventAction: 'download',
                eventLabel: 'fr',
            });
        }
    }, []);

    const homePath = simple
        ? url('homeWithLocaleSimple', { locale })
        : url('homeWithLocale', { locale });

    let frenchPath = simple
        ? url('homeWithLocaleSimple', { locale: 'fr' })
        : url('homeWithLocale', { locale: 'fr' });

    let englishPath = simple
        ? url('homeWithLocaleSimple', { locale: 'en' })
        : url('homeWithLocale', { locale: 'en' });

    if (screen !== null) {
        frenchPath = simple
            ? url('screenWithLocaleSimple', { locale: 'fr', screen })
            : url('screenWithLocale', { locale: 'fr', screen });

        englishPath = simple
            ? url('screenWithLocaleSimple', { locale: 'en', screen })
            : url('screenWithLocale', { locale: 'en', screen });
    }

    return (
        <div className={styles.container}>
            <Micromag
                story={locale === 'en' ? english : french}
                locale={locale}
                basePath={homePath}
                // memoryRouter
                beforeScreensMenuButton={
                    <>
                        {locale === 'fr' ? (
                            <a
                                href="/medias/fr.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.button}
                                onClick={trackPdf}
                            >
                                PDF
                            </a>
                        ) : null}
                        {locale === 'en' ? (
                            <a
                                href={frenchPath}
                                target="_self"
                                rel="noopener noreferrer"
                                className={styles.button}
                            >
                                Français
                            </a>
                        ) : (
                            <a
                                href={englishPath}
                                target="_self"
                                rel="noopener noreferrer"
                                className={styles.button}
                            >
                                English
                            </a>
                        )}
                    </>
                }
                pathWithIndex
                className={styles.micromag}
            />
        </div>
    );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
